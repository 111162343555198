<template>
    <OFormControl title="Review Responsible" class="col-12 col-md-4 mb-2">
        <ODataLookup :data-object="dsPersWithAccessComponentLkp"
            openonfocus
            :bind="sel=> { props.dataObject.current.ReviewResponsible_ID=sel.ID; props.dataObject.current.ReviewResponsible=sel.Name;}"
            class="form-control form-control-sm"
            :height="400"
            :value="props.dataObject.current.ReviewResponsible"
            @onbeforeclose="() => {props.dataObject.save()}">
            <OColumn name="ID" width="80"/> 
            <OColumn name="Name" width="300"/>
        </ODataLookup>
    </OFormControl>
    <div class="row row-container">
        <div class="col-12 row-container">
            <div class="row">
                <div class="col-12">
                    <h5 class="d-inline">Distribute / Grant access to Person</h5>
                    <input class="form-check-input ms-3" type="checkbox" value="" id="InclPersonsFromDistGrps" @change="inclPersonsFromGrps">
                    <label class="form-check-label d-inline ms-1" style="bottom: 2px; position: relative;" for="InclPersonsFromDistGrps">
                        {{$t('Incl. persons from Distr. groups')}}
                    </label>
                </div>
            </div>
            <o-data-grid :data-object="dsDocPersons" hide-grid-menu>
                <o-column field="Person" width="150" editable>
                    <template #editor="{modelValue: row}">
                        <OPersonsLookup v-model = "row.Person"
                            :bind="sel=>{row.Person_ID=sel.ID;row.Person=sel.Name}">
                        </OPersonsLookup>
                    </template>
                    <template #default="{row}">
                         <span :class="{ 'no-primary-access': !row.HasPrimaryAccess }">{{row.Person}}</span>
                    </template>
                </o-column>
                <o-column field="ForReview" width="120" editable></o-column>
                <o-column field="ForApproval" width="120" editable></o-column>
                <o-column field="Check" width="80" editable></o-column>
                <o-column field="ForInfo" width="80" editable></o-column>
                <o-column field="AllowEdit" width="100" editable></o-column>
                <o-column field="Subscribe" width="100" editable></o-column>
            </o-data-grid>
        </div>
    </div>
    <div class="row pt-2 mt-1 row-container">
        <div class="col-12 row-container">
            <h5>Distribute / Grant access to Org Units</h5>
            <o-data-grid :data-object="dsDocOrgUnits" hide-grid-menu>
                <o-column field="OrgUnit" width="200" v-slot:editor="{modelValue:row}" editable>
                    <OOrgunitsLookup :bind="sel=>{row.OrgUnit_ID = sel.ID; row.OrgUnit = sel.Name;}">
                        <template #orgunit>
                            <input type="text" class="form-select" id="orgUnitInput" v-model="row.OrgUnit">
                        </template>
                    </OOrgunitsLookup>
                </o-column>
            </o-data-grid>
        </div>
    </div>
    <div class="row pt-2 mt-1 row-container">
        <div class="col-12 row-container">
            <h5>Distribution Groups</h5>
            <o-data-grid :data-object="dsDocDistributions" hide-grid-menu>
                <o-column field="Distribution" width="200" editable v-slot:editor="{row}">
                    <ODataLookup :data-object="dsDocDistributionsLkp"
                        openonfocus
                        :bind="sel=> { row.Distribution_ID=sel.ID; row.Distribution=sel.Name;} "
                        :height="400"
                        :value="row.Distribution"
                        @onbeforeopen="setDocDistLookupWhereClause()">
                        <OColumn name="ID" width="80"/>
                        <OColumn name="Name" width="200"/>
                    </ODataLookup>
                </o-column>
            </o-data-grid>
        </div>
    </div>
</template>

<script setup>
    import { createDataObject } from 'o365.vue.ts'
    import OPersonsLookup from 'o365.vue.components.PersonsLookup.vue'
    import OOrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'

    const props = defineProps({
        dataObject: Object
    })

    const inclPersonsFromGrps = (e) => {
        if(e.target.checked){
            dsDocPersons.recordSource.whereClause = "Aggregated = 1";
        }else{
            dsDocPersons.recordSource.whereClause = "Aggregated = 0";
        }
        dsDocPersons.load();
    }

    const setDocDistLookupWhereClause = () => {
        dsDocDistributionsLkp.recordSource.whereClause = `${props.dataObject.current.OrgUnitIDPath} like IdPath`;
    }

    const dsDocPersonsDefinition = {
        id: 'dsDocPersons',
        viewName: 'aviw_Arena_DocumentsPersonsAggregateToggle',
        maxRecords: -1,
        whereClause: "Aggregated = 0",
        loadRecents: true,
        distinctRows: true,
        uniqueTable:"atbv_Arena_DocumentsPersons",
        allowDelete: true,
        allowInsert:true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Document_ID", type: "number" },
                {name: "Person_ID", type: "number"},
                {name: "ForReview", type: "bit"},
                {name: "ForApproval", type: "bit"},
                {name: "ForInfo", type: "bit"},
                {name: "Check", type: "bit"},
                {name: "Subscribe", type: "bit"},
                {name: "Person", type: "string"},
                {name: "AllowEdit", type: "bit"},
                {name: "ManuallyModified", type: "bit"},
                {name: "RepresentsCompany", type: "string"},
                {name: "DistributionSource", type: "string"},
                {name: "HasPrimaryAccess", type: "bit"},
                {name: "Closed", type: "date"}
            ]
    }, dsDocOrgUnitsDefinition = {
        id: 'dsDocOrgUnits',
        viewName: 'aviw_Arena_DocumentsOrgUnits',
        maxRecords: -1,
        whereClause: "Closed IS NULL",
        loadRecents: true,
        distinctRows: true,
        uniqueTable:"atbv_Arena_DocumentsOrgUnits",
        allowDelete: true,
        allowInsert:true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Document_ID", type: "number" },
                {name: "OrgUnit_ID", type: "number"},
                {name: "OrgUnit", type: "string"},
                {name: "Distributed", type: "date"},
                {name: "Closed", type: "date"}
            ]
    }, dsDocDistributionsDefinition = {
        id: 'dsDocDistributions',
        viewName: 'aviw_Arena_DocumentsDistributions',
        maxRecords: -1,
        whereClause: "Closed IS NULL",
        loadRecents: true,
        distinctRows: true,
        uniqueTable:"atbv_Arena_DocumentsDistributions",
        allowDelete: true,
        allowInsert:true,
        allowUpdate:true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Document_ID", type: "number" },
                {name: "Distribution_ID", type: "number"},
                {name: "Distribution", type: "string"},
                {name: "Closed", type: "date"}
            ]
    }, dsDocDistributionsLkpDefinition = {
        id: 'dsDocDistLkp',
        viewName: 'aviw_Arena_DistributionsLookup',
        maxRecords: -1,
        whereClause: "Closed IS NULL",
        loadRecents: true,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"}
            ]
    }, dsPersWithAccessLkpDefinition = {
        id: 'dsPersWithAccessComponentLkp',
        viewName: 'aviw_Arena_PersonsWithAccess',
        maxRecords: -1,
        loadRecents: true,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [
                {name: "ID", type: "number"},
                {name: "Name", type: "string"}
            ]
    }

    const dsDocPersons = createDataObject(dsDocPersonsDefinition);
    const dsDocOrgUnits = createDataObject(dsDocOrgUnitsDefinition);
    const dsDocDistributions = createDataObject(dsDocDistributionsDefinition);
    const dsDocDistributionsLkp = createDataObject(dsDocDistributionsLkpDefinition);
    const dsPersWithAccessComponentLkp = createDataObject(dsPersWithAccessLkpDefinition);

    props.dataObject.load();
</script>
